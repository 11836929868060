/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React examples
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";

function BenefitsTapshift() {
  return (
    <Container sx={{ marginTop: "64px" }}>
      <Grid container item xs={12} justifyContent="center">
        <Grid
          item
          xs={12}
          md={4}
          sx={{ ml: { xs: 0, md: "auto" }, mr: { xs: 0, md: 6 }, mb: { xs: 4, md: 0 } }}
        >
          <Stack spacing={{ xs: 4, md: 8 }}>
            <SimpleInfoCard icon="payment" title="Modular Components" />
            <SimpleInfoCard icon="insights" title="Great Features" />
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{ mr: { xs: 0, md: "auto" }, ml: { xs: 0, md: 6 }, mb: { xs: 4, md: 0 } }}
        >
          <Stack spacing={{ xs: 4, md: 8 }}>
            <SimpleInfoCard icon="access_alarms" title="Awesome Support" />
            <SimpleInfoCard icon="sentiment_satisfied" title="Modern Interface" />
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}

export default BenefitsTapshift;
