/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Information() {
  return (
    <MKBox component="section" py={6} mt={6}>
      <Container>
        <Grid container spacing={3} item xs={12} lg={8} mx="auto">
          <MKTypography
            component="h6"
            variant="button"
            opacity={0.7}
            textTransform="uppercase"
            fontWeight="bold"
          >
            Vorwort
          </MKTypography>
          <MKTypography variant="h3" mb={3}>
            Warum ein Zeiterfassungssystem 2022 für jedes Unternehmen notwendig ist.
          </MKTypography>
          <MKTypography variant="body1">
            Das Urteil des Bundesarbeitsgerichts zur verpflichtenden Arbeitszeiterfassung sorgt seit
            letzter Woche für reichlich Gesprächsstoff in der Arbeitswelt. Genau darum nehmen wir
            das Thema in diesem Blogpost ganz genau unter die Lupe.
            <br /> <br />
            Suchen Sie nach einer Lösung, die Arbeitszeiten Ihrer Mitarbeiter digital zu erfassen,
            oder benötigen Sie eine Anwendung um Urlaube und Abwesenheiten von Ihren Arbeitnehmern
            verwalten zu können? Suchen Sie nicht weiter. Unsere Experten haben jede der führenden
            Websites intensiv gestestet und nach Sicherheit, Qualität und Kosten bewertet. Das
            Ergebnis finden Sie hier!
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
