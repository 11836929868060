// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import SimplePricingCard from "examples/Cards/PricingCards/SimplePricingCard";

function Fazit() {
  return (
    <MKBox component="section" py={{ xs: 0, md: 12 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" md={8} mx="auto" textAlign="center">
          <MKTypography variant="h3">Unser Fazit</MKTypography>
        </Grid>
        <Grid container spacing={3} mt={6}>
          <Grid item xs={12} sm={6} lg={3}>
            <SimplePricingCard
              color="dark"
              title="Papershift"
              description="Free access for 2 members"
              price={{ value: "$199", type: "year" }}
              action={{ type: "internal", route: "/", label: "buy now" }}
              specifications={["Complete documentation", "Working materials in Sketch"]}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <SimplePricingCard
              color="dark"
              title="Personio"
              description="Free access for 30 members"
              price={{ value: "$299", type: "year" }}
              action={{ type: "internal", route: "/", label: "buy now" }}
              specifications={[
                "Complete documentation",
                "Working materials in Sketch",
                "2GB cloud storage",
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <SimplePricingCard
              variant="gradient"
              color="dark"
              title="Tapshift"
              description="Free access for 200 members"
              price={{ value: "$499", type: "year" }}
              action={{ type: "internal", route: "/", label: "buy now" }}
              specifications={[
                "Complete documentation",
                "Working materials in Sketch",
                "20GB cloud storage",
                "100 team members",
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <SimplePricingCard
              color="dark"
              title="TimeDoctor"
              description="Free access for all members"
              price={{ value: "$899", type: "year" }}
              action={{ type: "internal", route: "/", label: "buy now" }}
              specifications={[
                "Complete documentation",
                "Working materials in Sketch",
                "100GB cloud storage",
                "500 team members",
                "Premium support",
              ]}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Fazit;
